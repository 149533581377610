.features {
    background-color: var(--white);
    padding: space(5) space(3);

    display: grid;
    justify-content: center;
    align-items: center;

    grid-template-rows: auto space(4) 1fr;
    grid-template-columns: 1fr;

    grid-template-areas:
        "i"
        "."
        "t";

    &.image-reduced {
        .text {
            p {
                --paragraph-600-margin-top: 0;

                &.colored {
                    color: var(--purple);
                }

                &.subtitle {
                    margin: space(8) 0 space(4) 0;
                }
            }
        }
    }
    .text {
        grid-area: t;

        h2 {
            --heading-700-margin-top: 0;
            @extend %heading-700;
            font-weight: 700;
        }

        h3 {
            &:not(:first-child) {
                margin-top: 32px;
            }
            --heading-600-margin-top: 0;
            @extend %heading-600;
            font-weight: 700;
        }

        p {
            @extend %p-600;
            line-height: rem(34);
            font-weight: 400;

            &.subtitle {
                --heading-700-margin-top: 0;
                @extend %heading-700;
                font-weight: 700;
            }
        }

        a {
            margin-top: space(4);
        }

        ul {
            li {
                @extend %p-600;
                line-height: rem(34);
                font-weight: 400;

                svg {
                    color: var(--purple);
                }
            }
        }
    }

    .image {
        display: flex;
        justify-content: center;
        align-items: center;

        grid-area: i;

        img {
            object-fit: contain;
            height: 400px;
            @include up($tablet) {
                height: 800px;
            }
        }
    }

     &:nth-child(4) {
         padding-bottom: 0;
     }

    &:nth-child(5) {
        padding-top: 0;
    }
}

@include up($mobilePlus) {
    .features {
        padding-top: space(10);
        padding-bottom: space(10);
    }
}

@include up($tablet) {
    .features {
        padding: space(14) space(4);

        &:not(.image-reduced) {
            grid-template-columns: 1fr 50px 1fr;
            grid-template-rows: 1fr 1fr;

            grid-template-areas:
        "i . t"
        "i . t";
        }

        &.inversed {
            grid-template-areas:
        "t . i"
        "t . i";
        }

        .image {
            max-height: none;

            img {
                max-height: none;
            }
        }
    }
}

@include up($widescreen) {
    .features {
        grid-template-columns: 400px 100px 1fr;

        &.image-reduced {
            grid-template-columns: 400px 100px 1fr;
            grid-template-rows: 1fr 1fr;

            grid-template-areas:
        "i . t"
        "i . t";

        }
    }
}

@include up($hd) {
    .features {
        padding: space(14);
        justify-content: center;
        grid-template-columns: minmax(0, 660px) 200px minmax(0, 660px);
    }
}