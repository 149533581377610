.partners {
    padding-top: space(5);
    padding-bottom: space(5);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        --heading-700-margin-top: 0;
        @extend %heading-700;
        font-weight: 700;
    }

    & > p {
        text-align: center;
        margin: space(4) 0;

        a {
            font-variant-position: super;
            margin-left: space(1);
        }
    }

    tchatcha-slider {
        display: block;
        width: 100%;

        .swiper {
            width: 100%;
        }

        .swiper-slide {
            width: 150px;
            height: 80px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}