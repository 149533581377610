.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: space(4);
    font-size: rem(16);
    font-weight: 700;
    padding: space(1.5) space(7.5);
    transition: all .4s ease;

    &.btn-principal {
        background-color: var(--purple);
        border: 1px solid var(--purple);
        color: var(--white);

        &:hover {
            background-color: rgb(255, 255, 255);
            color: var(--purple);
        }
    }

    &.btn-black {
        background-color: var(--black);
        border: 1px solid var(--black);
        color: var(--white);

        &:hover {
            background-color: var(--white);
            color: var(--black);
        }
    }
}

a {
    &:not(.btn) {
        &:hover {
            color: var(--purple);
        }
    }
}