.hero {
    background-color: var(--white);
    padding: space(5) space(3);

    h1 {
       display: none;
    }

    p {
        @extend %p-600;
        line-height: rem(34);
        font-weight: 400;
        text-align: center;

        &.title {
            --heading-900-margin-top: 0;
            @extend %heading-900;

            @include up($tablet) {
                padding: 0 space(6);
            }
        }

        &.subtitle {
            @extend %heading-900;

            .colored {
                color: var(--blue);
            }
        }

        &.description {

        }
    }

    .actions {
        margin-top: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: space(2);
        grid-gap: space(2);
    }
}

@include up($widescreen) {
    .hero {
        p {
            &.subtitle {
                font-size: rem(60);
                line-height: rem(60);
            }
        }
    }
}

@include up($hd) {
    .hero {
        padding: space(14);
    }
}