body {
    font-size: $font-size;
    font-family: "Poppins", sans-serif;
    line-height: $line-height;
    color: $font-color;
}

.container {
    --container: calc(100% - (2 * #{$gap}));

    width: 100%;

    padding: 0 calc(50% - var(--container) / 2) 0;

    @include up(768) {
        --container: calc(#{$tablet} - (2 * #{$gap}));
    }

    @include up(1024) {
        --container: #{$tabletLandscape};
    }

    @include up(1216) {
        --container: #{$laptop};
    }

    @include up(1408) {
        --container: #{$widescreen};
    }
}
