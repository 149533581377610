footer {
    h2 {
        display: none;
    }
    .account {
        padding-top: space(5);
        padding-bottom: space(5);
        background-color: var(--purple-light);

        display: flex;
        align-items: center;
        flex-direction: column;

        .access_paths  {
            display: flex;
            flex-direction: column;
            align-items: center;

            a:first-child {
                margin-bottom: space(4);
            }
        }
    }

    .links {
        padding-bottom: space(10);
        background-color: var(--purple-light);

        .grid {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: space(4);

            .network {
                a {
                    &:not(:last-child) {
                        margin-right: space(2);
                    }
                }
            }
        }
    }

    .copyright {
        background-color: var(--white);

        height: 122px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .scroll-to-top {
            cursor: pointer;
            position: fixed;
            bottom: 80px;
            right: 40px;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 45px;
            height: 45px;
            background-color: var(--black);
            border: 1px solid var(--black);

            z-index: 99;

            border-radius: 50%;
            transition: all .4s ease;

            svg {
                color: var(--purple-light);
            }

            &:hover {
                background-color: var(--purple-light);

                svg {
                    color: var(--black);
                }
            }
        }
    }
}

@include up($mobilePlus) {
    footer {
        .account {
            padding-top: space(10);
            padding-bottom: space(10);

            h2 {
                font-size: rem(50);
                line-height: rem(60);
            }

            .access_paths {
                display: flex;
                flex-direction: row;

                a:first-child {
                    margin-right: space(4);
                    margin-bottom: 0;
                }
            }
        }
    }
}

@include up($tablet) {
    footer {
        .account {
            padding-top: space(20);
            padding-bottom: space(20);
        }

        .links {
            padding-bottom: space(14);

            .grid {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .copyright {
            .scroll-to-top {
                bottom: 40px;
            }
        }
    }
}

@include up($laptop) {
    footer {
        .links {
            .grid {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}