.events {
    background-color: var(--purple-light);
    padding-top: space(5);
    padding-bottom: space(5);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        --heading-700-margin-top: 0;
        @extend %heading-700;
        font-weight: 700;
    }

    & > p {
        text-align: center;
        margin: space(4) 0;

        a {
            font-variant-position: super;
            margin-left: space(1);
        }
    }

    tchatcha-slider {
        display: block;
        width: 100%;
        position: relative;

        .swiper {
            width: 100%;
            height: 300px;
        }

        .card {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: space(2);

            &--image {
                width: 100%;
                border-radius: space(.5);

                img {
                    height: 150px;
                    width: 100%;
                    object-fit: cover;
                    border-radius: space(.5);

                    transition: all ease .3s;
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }

            p {
                text-align: center;
                @extend %p-300;
            }
        }
    }

}