/*@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900");*/
@import "_scss/_generics/fonts";
@import "_scss/_theme/color";
@import "_scss/_theme/variables";
@import "_scss/_theme/animations";
@import "_scss/_theme/generics";

/* UI-KIT */
@import "~uikit/src/scss/utilities/all";
@import "~uikit/src/scss/base/reset";
@import "~uikit/src/scss/generics/typography";

/* Theme */
@import "_scss/_theme/layout";

/* Generics */
@import "./_scss/_generics/buttons";

/* Styles */
@import "./_scss/header";
@import "./_scss/hero";
@import "./_scss/event";
@import "./_scss/feature";
@import "./_scss/number";
@import "./_scss/contact";
@import "./_scss/partner";
@import "./_scss/footer";
@import "./_scss/page";
