.contact {
    background-color: var(--purple-light);
    padding-top: space(5);
    padding-bottom: space(5);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        --heading-700-margin-top: 0;
        @extend %heading-700;
        font-weight: 700;
    }

    .grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        margin-top: space(4);


        border-bottom: 1px solid var(--black);

        @include up($tablet) {
            grid-template-columns: repeat(3, 1fr);
        }

        .card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &--title {
                @extend %heading-500;
                --heading-500-margin-top: 0;
            }

            &--content {
                display: flex;
                padding: space(2) 0;
            }
        }
    }

    a {
        &.social-link {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;
            width: 48px;
        }
    }
}