.page-wrapper {
    .block-title {
        padding: space(20) 0;
        background-color: var(--white);

        h1 {
            text-align: center;

            --heading-900-margin-top: 0;
            @extend %heading-900;
            font-size: rem(60);
            line-height: rem(70);
        }
    }

    .blog-details {
        &__content {
            background-color: var(--white);
            padding-bottom: space(20);

            iframe {
                max-width: 100%;
            }

            h2, h3, h4 {
                margin-bottom: space(2);
                margin-top: space(2);
            }

            h2 {
                --heading-700-margin-top: 0;
                @extend %heading-700;
            }

            h3 {
                --heading-600-margin-top: 0;
                @extend %heading-600;
                font-weight: 700;
                margin-bottom: space(3);
            }

            blockquote, q {
                @extend %p-600;
                line-height: rem(34);
                font-weight: 400;
                font-style: italic;
            }

            ul {
                list-style: url('../images/check.svg');
                list-style-position: inside;
            }

            ol {
                list-style-type: decimal;
                list-style-position: inside;
            }

            a {
                text-decoration: underline;
            }
        }

        &__author {
            display: flex;
            align-items: center;
            margin-bottom: space(5);

            &-image {
                margin-right: space(4);
            }
        }
    }
}