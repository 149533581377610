@mixin link-bordered($color, $hoverColor: false) {
    a {
        span {
            &:before {
                content: '';
                width: 100%;
                height: 2px;
                background-color: $color;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: all .4s ease, -webkit-transform .4s ease;
                transform: scale(0, 1);
                transform-origin: right center;
            }
        }
    }

    &.active, &:hover {
        a {
            span {
                &:before {
                    -webkit-transform: scale(1, 1);
                    transform: scale(1, 1);
                    -webkit-transform-origin: left center;
                    transform-origin: left center;
                }
            }
        }
    }

    &:hover {
        a {
            span {
                &:before {
                    background-color: $hoverColor;
                }
            }
        }
    }
}