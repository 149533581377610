.numbers {
    background-color: var(--purple-light);
    padding-top: space(5);
    padding-bottom: space(5);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        --heading-700-margin-top: 0;
        @extend %heading-700;
        font-weight: 700;
    }

    & > p {
        text-align: center;
        margin: space(4) 0;

        a {
            font-variant-position: super;
            margin-left: space(1);
        }
    }

    .cards {
        display: grid;
        grid-gap: space(2);
        grid-template-columns: repeat(2, 1fr);

        .card {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;

            .number {
                --heading-900-margin-top: 0;
                @extend %heading-900;
                color: var(--purple);
                font-weight: 700;
            }
        }
    }
}