:root {
    --space: 8px;
}

//Typography
$font-size: 16px !default;
$line-height: 34px !default;
$font-color: var(--black) !default;

// Responsive
$gap: 24px !default;

$mobilePlus: 425px !default;
$tablet: 768px !default;
$tabletLandscape: 960px !default;
$laptop: 1024px !default;
$widescreen: 1200px !default;
$hd: 1366px !default;
$fullhd: 1920px !default;