header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;

    height: 130px;
    background-color: var(--white);
    padding-top: space(4) !important;
    padding-bottom: space(4) !important;

    .logo {
        display: flex;
        align-items: center;

    }

    nav {
        display: none;

        ul {
            display: flex;
            justify-content: center;

            li {
                @include link-bordered(#{var(--black)});

                a {
                    position: relative;
                    @extend %p-600;
                    font-size: rem(18);
                    line-height: rem(22);
                    font-weight: 600;

                    &:not(.btn) {
                        &:hover {
                            color: var(--black);
                        }
                    }
                }

                &:not(:last-child) {
                    a {
                        margin-right: space(6);
                    }
                }
            }
        }
    }

    .cta {
        display: none;
        @include link-bordered(#{var(--purple-light)}, #{var(--purple)});

        a {
            padding: space(1.5) space(4);
            justify-self: end;

            span {
                position: relative;
            }
        }
    }


    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: var(--purple-light);
        animation-name: slideInDown;
        animation-duration: 1s;
        animation-fill-mode: both;
    }

    &.header-content {
        grid-template-columns: 1fr;

        nav {
            display: block;
        }
    }
}

@include up($tablet) {
    header {
        .cta {
            display: block;
        }
    }
}

@include up($widescreen) {
    header {
        display: grid;
        grid-template-columns: 200px 1fr 200px;
        grid-gap: space(4);
        justify-content: center;
        align-items: center;

        nav {
            display: block;
        }
    }
}

